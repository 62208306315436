(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("luna-object-viewer"), require("luna-data-grid"), require("luna-dom-viewer"));
	else if(typeof define === 'function' && define.amd)
		define(["luna-object-viewer", "luna-data-grid", "luna-dom-viewer"], factory);
	else if(typeof exports === 'object')
		exports["LunaConsole"] = factory(require("luna-object-viewer"), require("luna-data-grid"), require("luna-dom-viewer"));
	else
		root["LunaConsole"] = factory(root["LunaObjectViewer"], root["LunaDataGrid"], root["LunaDomViewer"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__100__, __WEBPACK_EXTERNAL_MODULE__101__, __WEBPACK_EXTERNAL_MODULE__102__) {
return 